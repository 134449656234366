.earn {
    background-color: #0b0e11;
    width: 100%;
    height: 39vh;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    margin-top: 40px;
    padding: 0 20px;
}

@media (max-width: 768px) {
    .earn {
        height: 25vh;
        gap: 25px;
    }
}