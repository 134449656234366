.portfolio-container {
    margin-top: 70px;
}

@media (max-width: 768px) {
    .portfolio-container {
        height: 650px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 20px;
    }
}