.nav {
    height: 64px;
    width: 100%;
    background-color: #181a20;
    display: flex;
    align-items: center;
    padding: 0 30px;
    position: relative;
}

.mobile-canvas {
    background-color: #3f4246;
    width: 67%;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;
}

@media (max-width: 768px) {
    .nav {
        padding: 0 15px;
    }
    .mobile-canvas {
        width: 100%;
    }
    
}

.nav-logo img {
    height: 24px;
}

.nav-logo {
    position: relative;
    display: flex;
}

.nav-logo::after {
    content: "";
    position: absolute;
    right: -25px;
    top: 50%;
    width: 15px;
    height: 1px;
    background-color: white;
    transform: rotate(90deg) translate(0, -50%);
}

.nav-menu {
    display: flex;
    width: 320px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 35px;
}

.icons {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    gap: 20px;
    font-size: 18px;
    align-items: center;
    color: #eaecef;
    position: relative;
}



.icons i {
    cursor: pointer;
    z-index: 99;
}



.icons i:hover {
    color: orange;
}

.log-in {
    color: #eaecef;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    margin-right: 20px;

    &:hover {
        color: orange;
    }
}

.burger {
    display: none;
}

.off-canvas {
    position: absolute;
    top: 0;
    background-color: rgb(30, 35, 41);
    width: min(450px, 100%);
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    transition: all 0.31s linear;
    z-index: 999;
    display: none;
}

.canvas-items {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 50px);
    list-style: none;
}

@media (max-width: 1000px) {
    .mobile-hide {
        display: none !important;
    }

    .nav-logo::after {
        display: none;
    }

    .burger {
        display: inline-block;
        color:#eaecef;
        font-size: 22px;
        margin: 0;
        padding: 0;
        margin-left: 17px;
        cursor: pointer;
        margin-right: 10px;
    }

    .nav-logo img {
        height: 21px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 25px;
        font-size: 30px;
        color: #eaecef;
        cursor: pointer;
    }
    .off-canvas {
        display: flex;
    }
}
