.section-heading {
    font-size: 40px;
    font-weight: 600;
    color: #eaecef;
    margin: 0;
}

@media (max-width: 1024px) {
    .section-heading {
        font-size: 32px;
        line-height: 1.1em;
    }
}
@media (max-width: 768px) {
    .section-heading {
        font-size: 24px;
        line-height: 1.1em;
    }
}