.header-form-btn {
    padding: 15px 20px;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 5px;
    background-color: rgb(71, 77, 87);
    color: #eaecef;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: rgb(56, 60, 68);
    }
}

.header-form-btn i {
    font-size: 20px;
}