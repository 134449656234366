.help-icon {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.help-item {
    display: flex;
    align-items: center;
    height: 80px;
    gap: 10px;
    margin: 70px 0;
    width: 80%;
}

.help-text h2 {
    color: #eaecef;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.help-text p {
    color: #B7BDC6;
    line-height: 1.5em;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .help-item {
        flex-direction: column;
        align-items: flex-start;
    }
    .help-text p {
        font-size: 16px;
    }
}


@media (max-width: 768px) {
    .help-item {
        flex-direction: column;
        align-items: flex-start;
        height: 140px;
        margin-top: 0px;
        width: 100%;
    }

    .help-text p {
        font-size: 14px;
    }

    .help-icon {
        width: 70px;
        height: 70px;
    }
}