.popular-cryptos-container {
    height: 100vh;
    position: relative;
}

.section-header {
    display: flex;
    justify-content: space-between;
    width: min(1200px, 93%);
    margin: 40px auto;
    height: 80px;
    align-items: center;
}
.section-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: min(1200px, 93%);
    margin: 40px auto;
    gap: 30px;
}

.footer-header {
    font-size: 24px;
    font-weight: 600;
    color: #eaecef;
}

.error-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    font-weight: 600;
    color: #848E9C;
    display: flex;
    align-items: center;
    gap: 20px;
}



@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        margin-top: 50px;
        align-items: flex-start;
    }
    .footer-header {
        font-size: 20px;
        line-height: 1.5em;
    }
    
}