.footer {
    height: 8vh;
    background-color: #181a20;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text {
    color: #848E9C;
    font-size: 14px;
}

.footer-text a {
    color: #848E9C;
}
