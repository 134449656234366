.section-link {
    font-size: 14px;
    text-decoration: none;
    color: #848E9C;
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    
    &:hover {
        color: orange;
    }
}

.section-link i {
    font-size: 13px;
    margin-left: 5px;
}


