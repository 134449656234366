.all-coin-main {
    height: 115vh;
    background-color: #181a20;
    overflow: hidden;
}

.all-coin-main-container {
    width: min(1200px, 93%);
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.all-coin-main-tab {
    display: flex;
    width: 100%;
    height: 100px;
    gap: 30px;
    color: #848E9C;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    margin-top: 40px;
    flex-direction: column;

}

.head-sub-div {
    display: flex;
    justify-content: space-between;
}

.head-sub-div input {
    border: 1px solid #494e54;
    outline: none;
    background-color: transparent;
    padding: 12px 10px;
    width: 300px;
    color: #eaecef;
    border-radius: 4px;
    font-size: 15px;
}

.head-sub-div input:focus {
    border-color: #fcd535;
}

.all-coin-main-tab-head {
    color: #eaecef;
    font-size: 20px;
    font-weight: 500;
}

.all-coin-main-tab i {
    margin-right: 5px;
}

.all-coin-main-cryptos {
    display: flex;
    flex-direction: column;
}

.all-coin-main-cryptos-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    color: #eaecef;
    font-size: 12px;
    padding: 0 10px;
    background-color: #0B0E11;
    margin-top: 20px;
}
.all-coin-main-cryptos-head p:first-child {
    width: 420px;
}
.all-coin-main-cryptos-head p:nth-child(2) {
    width: 210px;
}
.all-coin-main-cryptos-head p:nth-child(3) {
    width: 230px;
}
.all-coin-main-cryptos-head p:nth-child(4) {
    width: 280px;
}

.switch-tab {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #B7BDC6;
    margin-left: auto;
}

.switch-tab p {
    background-color: #33363f;
    padding: 5px 8px;
    border-radius: 5px;
    color: #eaecef;
}

.switch-tab i {
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 2px;

    &:hover {
        color: #fcd535;
    }
}

@media (max-width: 1024px) {
    .all-coin-main-cryptos-head p:first-child {
        width: 520px;
    }


}

@media (max-width: 768px) {
    .all-coin-main {
        height: 133vh;
    }

    .all-coin-main-tab-head{
        font-size: 18px;
        line-height: 1.4em;
    }
    .all-coin-main-cryptos-head {
        margin-top: 40px;
    }

    .head-sub-div input {
        font-size: 14px;
        width: 250px;
    }

    .all-coin-hide-mobile {
        display: none;
    }
    .all-coin-main-cryptos-head p:nth-child(2) {
        padding-left: 5px;
    }
    .all-coin-main-cryptos-head p:first-child {
        width: 500px;
    }
   
    

}

@media (max-width: 420px) {
    .all-coin-main-cryptos-head p:first-child {
        width: 370px;
    }
}