.each-slide-effect {
    display: flex;
}

.banner-section {
    margin: 0 auto;
    width: 100%;
    height: 55vh;
    padding: 50px 0;
    border-radius: 24px 24px 0 0;
    display: block !important;
}

.banner {
    height: 270px;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
}

.slide {
    margin: 0 auto;
    width: min(1200px, 94%) !important;
}

.indicator {
    cursor: pointer;
    padding: 5px;
    text-align: center;
    background-color: #4a5056;
    border-radius: 50%;
    margin: 0 5px;
    transition: all 0.1s ease-in-out;
}

.indicator.active {
    color: #fff;
    background: #fcd535;
    transform: scaleX(1.7);
    border-radius: 4px / 7px;
}

@media (max-width: 768px) {
    .banner {
        object-fit: contain;
        height: 100%;
    }
    .banner-section {
        height: 27vh;
        padding: 40px 0;
        margin-bottom: 10px;
    }

}