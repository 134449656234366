.main {
    height: auto;
    width: 100%;
    background-color: #181a20;
    border-radius: 24px 24px 0 0;
}

@media (max-width: 768px) {
    .main {
        padding: 0 3px;
    }
}