.nav-link {
    color: #eaecef;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: flex;
    cursor: pointer;

    &:hover {
        color: orange;
    }
}



@media (max-width: 1000px) {
    .mobile-link {
        width: 94%;
        font-size: 18px;
    }


}

