.loader {
    display: flex;
    align-items: center;
}

.bar {
    display: inline-block;
    width: 3px;
    height: 15px;
    background-color: #fcd535;
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
    height: 25px;
    margin: 0 5px;
    animation-delay: .25s;
}

.bar:nth-child(3) {
    animation-delay: .5s;
}

@keyframes scale-up4 {
    20% {
        transform: scaleY(1.3);
    }

    40% {
        transform: scaleY(1);
    }
}