.coin-info {
    width: 60%;
    padding: 15px;
    position: relative;
}

.coin-heading-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.coin-page-name {
    display: flex;
    align-items: center;
}

.coin-page-name img {
    width: 50px;
    height: auto;
    margin-right: 20px;
    border-radius: 50%;
}

.coin-page-name p {
    text-transform: uppercase;
    color: #848E9C;
    font-weight: 400 !important;
    position: relative;
    top: 8px;
    margin-left: 5px;
}

.current_price {
    font-size: 28px;
    color: #eaecef;
    font-weight: 600;
}

.coin-table-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto 2rem;
}
table {
    width: 100%;
    margin: 0.5rem 0;
    color: #eaecef;
}

td, th {
    padding: 11px;
    text-align: center;
}

td {
    font-size: 15px;
}

th {
    background-color: #0b0e11;
    font-weight: 600;
}

.coin-other-data {
    width: 100%;
    display: flex;
    gap: 20px;
}

.left-data, .right-data {
    flex-grow: 1;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #eaecef;
}

.left-data h2, .right-data h2 {
    border-bottom: 2px solid #838b95;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    font-weight: 600;
    font-size: 16px;
    margin: 6px 0;
}

.left-data p, .right-data p {
    font-weight: 400;
    font-size: 15px;
}

.about-coin {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    margin-top: 30px;
    padding: 0 5px;
    position: relative;
    margin-bottom: 35px;
}

.about-coin-head {
    color: #eaecef;
    font-size: 32px;
    font-weight: 600;
    margin-top: 50px;
}

.about-coin-text {
    color: #eaecef;
    line-height: 1.6em;
}
.about-coin-text a {
    color: #fcd535;
}

.text-hide {
    width: 100%;
    height: 100px;
    position: absolute;
    background-image: linear-gradient(rgba(24, 26, 32, 0) 0%, rgb(24, 26, 32) 100%);
    bottom: 0;
    left: 0;
    right: 0;
}

.view-more {
    color: orange;
    z-index: 99;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 18px;
}








@media (max-width: 1024px) {
    .coin-other-data {
        flex-direction: column;
    }
    .about-coin-head {
        font-size: 28px;
    }
}

@media (max-width: 768px) {
    .coin-info {
        width: 100%;
        padding: 0;
    }

    .coin-page-name img {
        width: 40px;
        margin-right: 10px
    }

    th, td {
        font-size: 14px;
        padding: 5px;
    }

    .coin-page-name p {
        top: 3px;
        font-size: 14px;
    }

    .coin-heading-info {
        margin-top: 20px;
    }
}

@media (max-width: 342px) {
    th, td {
        padding: 3px;
    }
}